import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { callEvent } from '../analytics';
import { setData, getData } from '../utils';


const Settings = () => {
    const [theme, setTheme] = useState(() => getData('theme', window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'));
    const [endConfirmation, setEndConfirmation] = useState(() => getData('endConfirmation', true));
    const [showNextButton, setShowNextButton] = useState(() => getData('showNextButton', false));
    const [enableCompression, setEnableCompression] = useState(() => getData('enableCompression', false));

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Настройки';
        callEvent('OPEN_PAGE', {page: 'settings', settings: {
            theme: getData('theme', window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'),
            endConfirmation: getData('endConfirmation', true),
            showNextButton: getData('showNextButton', false),
            enableCompression: getData('enableCompression', false)
        }});
    }, []);

    useEffect(() => {
        setData('theme', theme);
        document.documentElement.dataset.theme = theme;
    }, [theme]);
    useEffect(() => setData('endConfirmation', endConfirmation), [endConfirmation]);
    useEffect(() => setData('showNextButton', showNextButton), [showNextButton]);
    useEffect(() => setData('enableCompression', enableCompression), [enableCompression]);

    const resetSettings = () => {
        if (window.confirm('Вы действительно хотите сбросить все настройки? Ваши результаты будут удалены, а настройки вернутся к значениям по умолчанию')) {
            localStorage.clear();
            setData('resetSuccessful', true);
            window.location.href = '/';
        }
    };

    return (
        <div className="main-container">
            <h1>⚙️ Настройки</h1>
            <h2>⚙️ Общие настройки</h2>
            <div className="settings">
                <label><input type="checkbox" checked={enableCompression} onChange={() => setEnableCompression(!enableCompression)}/> Использовать картинки низкого качества для экономии трафика</label>
            </div>
            <h2>🖼️ Настройки отображения</h2>
            <div className="settings">
                <label><input type="checkbox" checked={theme === 'dark'} onChange={() => setTheme(theme === 'light' ? 'dark' : 'light')}/> Использовать темную тему</label>
            </div>
            <h2>📜 Настройки тестирования</h2>
            <div className="settings">
                <label><input type="checkbox" checked={endConfirmation} onChange={() => setEndConfirmation(!endConfirmation)}/> Запрашивать подтверждение при нажатии кнопки «Завершить тестирование»</label>
                <label><input type="checkbox" checked={showNextButton} onChange={() => setShowNextButton(!showNextButton)}/> Показывать правильность ответов во время тестирования</label>
            </div>
            <button onClick={resetSettings}>Сбросить настройки</button>
            <button onClick={() => navigate('/')}>На главную</button>
        </div>
    );
};

export default Settings;