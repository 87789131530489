import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { callEvent } from '../analytics';
import { exams } from '../data';
import { formatImage, setData, getData } from '../utils';


const General = () => {
    const param = useParams();
    const navigate = useNavigate();

    const allQuestions = Array.prototype.concat(...exams.map(element => element.questions ? element.questions : null)).slice(1);

    const [userAnswers, setUserAnswers] = useState([]);
    const [answer, setAnswer] = useState(null);
    const [questionId, setQuestionId] = useState(0);
    const [showResults, setShowResults] = useState(false);
    const [showOnlyIncorrect, setShowOnlyIncorrect] = useState(false);
    const [showNextButton, setShowNextButton] = useState(false);
    const [date, setDate] = useState('');
    const [showExplanation, setShowExplanation] = useState([]);
    const [showRuntimeExplanation, setShowRuntimeExplanation] = useState([]);

    const [exam, setExam] = useState(null);
    const [correct, setCorrect] = useState(null);
    const [correctUserAnswers, setCorrectUserAnswers] = useState(null);

    useEffect(() => {
        document.title = 'Тестирование | Пробный экзамен';
        callEvent('EXAM_START', {exam_id: 69});
        let _exam = {id: 69, name: 'Пробный экзамен', questions: Array.from({length: 40}, () => allQuestions[Math.floor(Math.random() * allQuestions.length)])};
        setExam(_exam);
        setCorrect(_exam.questions.map((data, key) => data.options.findIndex(p => p.correct)));
    }, []);

    useEffect(() => {
        setCorrectUserAnswers(userAnswers.map((element, index) => element == correct[index] ? 1 : 0).reduce((pv, cv) => pv + cv, 0));
    }, [userAnswers]);

    const handleAnswer = () => {
        let newUserAnswers = [...userAnswers, answer];
        setUserAnswers(newUserAnswers);
        if (getData('showNextButton', false)) {
            setShowNextButton(true);
        } else {
            nextQuestion();
        }
    };

    const nextQuestion = () => {
        if (showNextButton) {
            setShowNextButton(false);
        }
        setAnswer(null);
        if (questionId + 1 < exam.questions.length) {
            setQuestionId(questionId + 1);
        } else {
            setDate(new Date().toLocaleString());
            setShowResults(true);
            processSaving(userAnswers);
        }
    };

    const endTest = () => {
        if (!getData('endConfirmation', true) || window.confirm('Вы действительно хотите завершить тестирование?')) {
            setDate(new Date().toLocaleString());
            setShowResults(true);
            processSaving(userAnswers);
        }
    };

    const processSaving = (_userAnswers) => {
        let report = {
            id: exam.id,
            name: exam.name,
            date: new Date().toLocaleString(),
            answers: _userAnswers,
            correct: _userAnswers.map((element, index) => element == correct[index] ? 1 : 0).reduce((pv, cv) => pv + cv, 0),
            total: exam.questions.length
        };
        let key = 'reports' + exam.id;
        setData(key, [...getData(key, []), report]);
        callEvent('EXAM_END', {report: report});
    };

    const changeAnswer = (answer) => {
        setAnswer(answer);
        if (getData('showNextButton', false)) {
            let newUserAnswers = [...userAnswers, answer];
            setUserAnswers(newUserAnswers);
            setShowNextButton(true);
        }
    };

    if (!exam) {
        return null;
    }

    if (!showResults) {
        return <div className="main-container">
            <progress className="test-progress" value={questionId} max={exam.questions.length}></progress>
            <h1>💡 Вопрос {questionId + 1} из {exam.questions.length}</h1>
            {exam.questions[questionId].image && <img alt="" src={formatImage(exam.questions[questionId].image)}/>}
            <h2>{exam.questions[questionId].text}</h2>
            <div className="question">
                {exam.questions[questionId].options.map((data, key) => {
                    return <label key={key}>
                        <input disabled={showNextButton} type="radio" checked={answer === key} onChange={event => changeAnswer(parseInt(event.target.value, 10))} value={key}/> {!getData('showNextButton', false) || !showNextButton ? <>
                            {data.text}
                        </> : (data.correct ? <>
                                <div className="answer-correct">{data.text}</div>
                            </> : (answer == key ? <>
                                    <div className="answer-incorrect">{data.text}</div>
                                </> : <>
                                    {data.text}
                                </>
                            )
                        )}
                    </label>;
                })}
                {(getData('showNextButton', false) && showNextButton && exam.questions[questionId].comment && 
                    (!showRuntimeExplanation.includes(questionId) ? <p>
                            <a href="javascript:void(0);" onClick={() => setShowRuntimeExplanation([...showRuntimeExplanation, questionId])}>Показать объяснение</a>
                        </p> : <p>
                            <b>Объяснение:</b> {exam.questions[questionId].comment}
                        </p>
                    )
                )}
            </div>
            {!getData('showNextButton', false) ? (
                <button disabled={answer == null} onClick={handleAnswer}>Далее</button>
            ) : (
                <button disabled={!showNextButton} onClick={nextQuestion}>Далее</button>
            )}
            <button className="cancel" onClick={endTest}>Завершить тестирование</button>
        </div>;
    } else {
        return <div className="main-container">
            <h1>✔️ Тестирование завершено</h1>
            {correctUserAnswers >= 32 ? (
                <h3 className="success">Тест сдан</h3>
            ) : (
                <h3 className="warning">Тест провален</h3>
            )}
            <h3 className="no-margin">
                Ваш результат: <div className={Math.floor(correctUserAnswers / exam.questions.length * 100) < 70 ? 'red' : (Math.floor(correctUserAnswers / exam.questions.length * 100) < 90 ? 'yellow' : 'green')}>
                    {correctUserAnswers} из {exam.questions.length} ({Math.floor(correctUserAnswers / exam.questions.length * 100)}%)
                </div>
            </h3>
            <h3 className="no-margin">Название теста: <b>{exam.name}</b></h3>
            <h3 className="no-margin">Дата и время сдачи: <b>{date}</b></h3>
            {userAnswers.length > 0 &&
                <div>
                    <h1>📝 Ваши ответы</h1>
                    <div className="answer">
                        <label>
                            <input type="checkbox" checked={showOnlyIncorrect} onChange={() => setShowOnlyIncorrect(!showOnlyIncorrect)}/> Показать только неверные ответы
                        </label>
                        {userAnswers.map((element, index) => {
                            if (!showOnlyIncorrect || element != correct[index]) {
                                return <div key={index}>
                                    <h2>Вопрос {index+1}</h2>
                                    {exam.questions[index].image && <img alt="" src={formatImage(exam.questions[index].image)}/>}
                                    <h3>{exam.questions[index].text}</h3>
                                    <div className="icon-container">
                                        <img alt="" className="icon" src={element == correct[index] ? '/images/correct.png' : '/images/wrong.png'}/>
                                        <div>
                                            <h4 className="no-margin">Правильный ответ: <b>{exam.questions[index].options.filter(p => p.correct)[0].text}</b></h4>
                                            <h4 className="no-margin">Ваш ответ: <b>{exam.questions[index].options[element].text}</b></h4>
                                        </div>
                                    </div>
                                    {(exam.questions[index].comment && (!showExplanation.includes(index) ? <p>
                                            <a href="javascript:void(0);" onClick={() => setShowExplanation([...showExplanation, index])}>Показать объяснение</a>
                                        </p> : <p>
                                            <b>Объяснение:</b> {exam.questions[index].comment}
                                        </p>
                                    ))}
                                </div>;
                            }
                            return null;
                        })}
                    </div>
                </div>
            }
            <button onClick={() => navigate('/')}>На главную</button>
            <div className="copyright">
                ⚙️ <Link to={'/settings'}>Настройки</Link>
            </div>
        </div>;
    }
};

export default General;