export function setData(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

export function getData(key, default_value = null) {
    let item = localStorage.getItem(key);
    if (item === null) {
        return default_value;
    }
    try {
        return JSON.parse(item);
    } catch {
        return item;
    }
}

export function formatImage(id) {
    let min = getData('enableCompression', false) ? '.min' : '';
    return '/images/jpg/' + id + min + '.jpg';
}

export function generateHash() {
    const hex = '0123456789ABCDEF';
    let output = '';
    for (let i = 0; i < 16; ++i) {
        output += hex.charAt(Math.floor(Math.random() * hex.length));
    }
    return output;
}