import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { callEvent } from '../analytics';
import { exams } from '../data';
import { formatImage } from '../utils';

import ReactModal from 'react-modal';

const Answers = () => {
    const param = useParams();
    const exam = exams.find(p => p.id == param.id);

    const [showOnlyCorrect, setShowOnlyCorrect] = useState(false);
    const [fileReportOpen, setFileReportOpen] = useState(false);
    const [fileReportSentOpen, setFileReportSentOpen] = useState(false);
    const [showExplanation, setShowExplanation] = useState([]);
    const navigate = useNavigate();

    //const [reportQuestion, setReportQuestion] = useState(0);
    //const [reportType, setReportType] = useState(0);
    //const [comment, setComment] = useState('');

    useEffect(() => {
        document.title = 'Ответы | ' + exam.name;
        callEvent('SHOW_ANSWERS', {exam_id: exam.id});
    }, []);

    //const sendReport = () => {
    //    callEvent('REPORT_ERROR', {exam_id: exam.id, question: reportQuestion, comment: comment, type: reportType});
    //    setFileReportOpen(false);
    //    setFileReportSentOpen(true);
    //}

    //const fileReport = question => {
    //    setReportQuestion(question);
    //    setReportType(0);
    //    setComment('');
    //    setFileReportOpen(true);
    //};

    return <div className="main-container">
        {/* <ReactModal className="modal" overlayClassName="overlay" isOpen={fileReportSentOpen}>
            <h2 className="report-header"><img alt="" className="test-passed-icon" src="/images/correct.png"/> Спасибо!</h2>
            <h3>Сообщение об ошибке отправлено и будет обработано в ближайшее время</h3>
            <button onClick={() => setFileReportSentOpen(false)}>Вернуться к тесту</button>
        </ReactModal>
        <ReactModal className="modal" overlayClassName="overlay" isOpen={fileReportOpen}>
            <h2>Сообщить об ошибке</h2>
            <h3 className="no-margin">Название теста: <b>{exam.name}</b></h3>
            <h3 className="no-margin">Номер вопроса: <b>{reportQuestion}</b></h3>
            <div className="report">
                <label className="report-option">
                    <input type="radio" checked={reportType == 1} onChange={event => setReportType(event.target.value)} value="1"/> Опечатка в вопросе, ответах или объяснении
                </label>
                <label className="report-option">
                    <input type="radio" checked={reportType == 2} onChange={event => setReportType(event.target.value)} value="2"/> Неверный ответ отмечен как правильный
                </label>
                <label className="report-option">
                    <input type="radio" checked={reportType == 3} onChange={event => setReportType(event.target.value)} value="3"/> Объяснение не соответствует вопросу
                </label>
                <label className="report-option">
                    <input type="radio" checked={reportType == 4} onChange={event => setReportType(event.target.value)} value="4"/> Другое
                </label>
            </div>
            <textarea spellCheck="false" value={comment} onChange={event => setComment(event.target.value)} placeholder="Комментарий (опционально)"></textarea>
            <button disabled={reportType === 0} onClick={sendReport}>Отправить</button>
            <button onClick={() => setFileReportOpen(false)}>Назад</button>
        </ReactModal> */}
        <h1>📝 Ответы на тест</h1>
        <h3 className="no-margin">Название теста: <b>{exam.name}</b></h3>
        <h3 className="no-margin">Количество вопросов: <b>{exam.questions.length}</b></h3>
        <div className="answer">
            <label><input type="checkbox" checked={showOnlyCorrect} onChange={() => setShowOnlyCorrect(!showOnlyCorrect)}/> Показать только правильные ответы</label>
            {exam.questions.map((element, index) => {
                return <div>
                    <h2>Вопрос {index+1}</h2>
                    {element.image && <img alt="" src={formatImage(element.image)}/>}
                    <h3>{element.text}</h3>
                    <ul>
                        {element.options.map(_element => {
                            if (_element.correct) {
                                return <li><h4 className="no-margin"><span>{_element.text}</span></h4></li>;
                            } else if (!showOnlyCorrect) {
                                return <li><h4 className="no-margin">{_element.text}</h4></li>;
                            }
                            return null;
                        })}
                    </ul>
                    {(element.comment && (!showExplanation.includes(index) ? (
                            <a href="javascript:void(0);" onClick={() => setShowExplanation([...showExplanation, index])}>Показать объяснение</a>
                        ) : <>
                            <b>Объяснение:</b> {element.comment}
                        </>
                    ))}
                    {/* <div className="margin-top">
                        <a href="javascript:void(0);" onClick={() => fileReport(index+1)}>Сообщить об ошибке</a>
                    </div> */}
                </div>;
            })}
        </div>
        <button onClick={() => navigate('/')}>На главную</button>
        <div className="copyright">
            ⚙️ <Link to={'/settings'}>Настройки</Link>
        </div>
    </div>;
};

export default Answers;