import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { callEvent } from '../analytics';
import { exams } from '../data';
import { getData, setData } from '../utils';


const Home = () => {
    const navigate = useNavigate();

    const [hidePassed, setHidePassed] = useState(() => getData('hidePassed', false));

    useEffect(() => {
        setData('hidePassed', hidePassed);
    }, [hidePassed]);

    useEffect(() => {
        document.title = 'Главная страница';
        callEvent('OPEN_PAGE', {page: 'home', settings: {
            theme: getData('theme', window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'),
            endConfirmation: getData('endConfirmation', true),
            hidePassed: getData('hidePassed', false),
            showNextButton: getData('showNextButton', false),
            enableCompression: getData('enableCompression', false)
        }});
    }, []);

    const getTextMinutes = minutes => {
        if ([1, 21, 31, 41, 51].includes(minutes)) return minutes + ' минута';
        if ([2, 3, 4, 22, 23, 24, 32, 33, 34, 42, 43, 44, 52, 53, 54].includes(minutes)) return minutes + ' минуты';
        return minutes + ' минут';
    };

    const getTextQuestions = questions => {
        if ([1, 21, 31, 41, 51, 61, 71, 81, 91, 101, 121, 131, 141, 151, 161, 171, 181, 191].includes(questions)) return questions + ' вопрос';
        if ([2, 3, 4, 22, 23, 24, 32, 33, 34, 42, 43, 44, 52, 53, 54, 62, 63, 64, 72, 73, 74, 82, 83, 84, 92, 93, 94, 102, 103, 104, 122, 123, 124, 132, 133, 134, 142, 143, 144, 152, 153, 154, 162, 163, 164, 172, 173, 174, 182, 183, 184, 192, 193, 194].includes(questions)) return questions + ' вопроса';
        return questions + ' вопросов';
    };

    return <div className="main-container">
        <h1>📄 Выберите тест:</h1>
        {exams.find(data => getData('reports' + data.id, []).find(p => p.correct === p.total)) && <div className="answer">
            <label><input type="checkbox" checked={hidePassed} onChange={() => setHidePassed(!hidePassed)}/> Скрыть пройденные тесты</label>
        </div>}
        {exams.map((data, key) => {
            if (hidePassed && getData('reports' + data.id, []).find(p => p.correct === p.total))
                return <></>;
            return <div className="card-container" key={key} onClick={() => navigate('/action/' + data.id)}>
                {data.questions && getData('reports' + data.id, []).length > 0 && (
                    getData('reports' + data.id, []).find(p => p.correct >= p.total) ? <>
                        <progress className="exam-progress full" value={100} max={100}></progress>
                        <div className="exam-progress-container"></div>
                    </> : <>
                        <progress className="exam-progress" value={Math.max.apply(Math, getData('reports' + data.id, []).map(data => data.correct))} max={data.questions.length}></progress>
                        <div className="exam-progress-container"></div>
                    </>
                )}
                <div className="card">
                    <h2>{data.name}</h2>
                    <div className="subtext">
                        {getTextQuestions(data.questions ? data.questions.length : 40)} • {getTextMinutes(Math.ceil((data.questions ? data.questions.length : 40) / 6))}<br/>
                        {data.questions && getData('reports' + data.id, []).length > 0 && <>
                            Лучший результат: {Math.floor(Math.max.apply(Math, getData('reports' + data.id, []).map(data => data.correct)) / data.questions.length * 100)}%
                        </>}
                    </div>
                </div>
            </div>;
        })}
        <div className="copyright">
            ⚙️ <Link to={'/settings'}>Настройки</Link>
        </div>
    </div>;
};

export default Home;