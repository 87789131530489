import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Modal from 'react-modal';

import './assets/css/style.css';

import Home from './components/Home';
import Action from './components/Action';
import Answers from './components/Answers';
import Exam from './components/Exam';
import General from './components/General';
import Settings from './components/Settings';

import { setData, getData, generateHash } from './utils';


const App = () => {
    useEffect(() => {
        document.documentElement.dataset.theme = getData('theme', window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
        if (!getData('id')) {
            setData('id', generateHash());
        }
    }, []);

    return <>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/action/:id" element={<Action/>}/>
                <Route path="/answers/:id" element={<Answers/>}/>
                <Route path="/exam/:id" element={<Exam/>}/>
                <Route path="/general" element={<General/>}/>
                <Route path="/settings" element={<Settings/>}/>
            </Routes>
        </BrowserRouter>
    </>;
}


Modal.setAppElement('#root');
ReactDOM.render(<App/>, document.getElementById('root'));