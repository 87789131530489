import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { exams } from '../data';


const Action = () => {
    const param = useParams();
    const exam = exams.find(p => p.id == param.id);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = exam.name
    }, []);

    return <div className="main-container">
        {exam.questions ? <h1>📜 Выберите действие:</h1> : <h1>📜 Пробный экзамен</h1>}
        {!exam.questions && <p>
            <b>Количество вопросов:</b> 40 из {exams.reduce((current, value) => current + (value.questions ? value.questions.length : 0), 0)}<br/>
            <b>Алгоритм выбора вопросов:</b> случайные вопросы<br/>
            <b>Проходной балл:</b> 32 (80%)
        </p>}
        <div>
            <button onClick={() => navigate(exam.questions ? '/exam/' + param.id : '/general')}>Начать тестирование</button>
        </div>
        {exam.questions && <div>
            <button onClick={() => navigate('/answers/' + param.id)}>Показать ответы</button>
        </div>}
        <div>
            <button className="cancel" onClick={() => navigate('/')}>На главную</button>
        </div>
        <div className="copyright">
            ⚙️ <Link to={'/settings'}>Настройки</Link>
        </div>
    </div>;
};

export default Action;